<template>
  <div>
    <sm-loader v-if="getLoanData.loading" />
    <div class="flex flex-col text-14 mb-5 mt-2 font-bold" v-else>
      <span>Total Requested Amount: {{ totalRequestedAmount | currency }}</span>
      <span
        >Total Amount To Be Disbursed: {{ totalLoanAmount | currency }}</span
      >
    </div>

    <div class="border border-solid border-blue-200">
      <datatable
        :data="loans.data"
        :columns="loans.columns"
        :query="searchQuery"
        :loading="loans.loading"
        :on-click="click"
        :selectable="true"
        :exportable="true"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/personal/loans?review=operations_review`"
        :search-field="searchField"
        ref="table"
        @selection-update="selectionUpdate"
      />
    </div>

    <div
      class="fixed bottom-20 right-5 sm:right-20 ml-5 sm:mr-20 rounded-lg bg-white p-6 z-10 shadow-lg max-w-500px"
    >
      <div class="text-sm text-gray-700 mb-4">
        {{ selection.length }} Loan{{
          selection.length !== 1 ? "s" : ""
        }}
        Selected!
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-4">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div class="mb-10 max-h-200px overflow-y-auto">
        <template v-for="(failure, n) in loans.failures">
          <div
            :class="{
              'border-b border-blue-200 pb-1 mb-2':
                n < loans.failures.length - 1
            }"
            :key="n"
          >
            <div class="flex items-center text-xs">
              <span class="mr-3">Loan ID: {{ failure.loan.id }}</span>
              <span>Reason: {{ failure.reason }}</span>
            </div>
          </div>
        </template>
      </div>

      <form-group
        type="select"
        :options="lenderOptions"
        left-icon="business-outline"
        name="lender"
        :form="form"
        :loading="lenders.loading"
        v-model="form.data.lender.value"
      >
        Select Lender
      </form-group>

      <div>
        <button
          class="btn btn-blue btn-sm mr-3"
          @click.prevent="disburse"
          :disabled="
            form.loading || !selection.length || !form.data.lender.value
          "
        >
          <ion-icon
            name="checkmark-done-outline"
            class="text-lg mr-2"
          ></ion-icon>
          <span v-if="form.loading">Marking</span>
          <span v-else>Mark As Disbursed</span>
        </button>
        <button
          class="btn btn-gray btn-sm"
          @click.prevent="clear"
          :disabled="form.loading || !selection.length"
        >
          <ion-icon name="close-circle-outline" class="text-lg mr-2"></ion-icon>
          <span>Clear Selection</span>
        </button>
      </div>
    </div>

    <modals-user
      ref="viewModal"
      :selected-user="selectedUser"
      :allow-delete="false"
    />

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="operationVerifyModal"
    >
      <h4 class="text-2xl font-bold mb-16">
        Operations Verify Loan
      </h4>

      <div>
        <template v-if="verifyError">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ verifyError }}</span>
          </div>
        </template>
        <template v-if="selectedLoan">
          <p class="text-gray-500">Name</p>
          <div class="rounded p-2 h-12">
            <div class="text-xl font-bold">
              {{ selectedLoan.user.name }}
              {{ selectedLoan.user.last_name }}
            </div>
          </div>

          <p class="text-gray-500 mt-4">Amount Requested</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-4">
            <div class="text-xl font-bold">
              ₦{{ selectedLoan.loan_amount | currency }}
            </div>
          </div>

 
        </template>

        <div class="text-center">
          <button
            @click.prevent="confirmOperationVerify"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="false"
          >
            <template v-if="loading">Verifying...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-xs mb-10">
        {{ successMessage }}
      </div>
    </modal>

  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: ""
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    }
  },
  data() {
    return {
      selection: [],
      checkmark: require("@/assets/checkmark-base.svg"),
      lenders: this.$options.resource([]),
      loans: this.$options.resource([], {
        selected: null,
        columns: [
          {
            name: "id",
            th: "Loan ID"
          },
          {
            name: "name",
            th: "User Name",
            render: loan => `${loan.user?.name} ${loan.user?.last_name || ""}`
          },
          {
            name: "company",
            th: "Company",
            render: loan => loan.user.company?.name
          },
          {
            name: "requested_amount",
            th: "Amount Requested",
            render: loan =>
              `₦ ${this.$options.filters.currency(loan?.requested_amount)}`
          },
          {
            name: "loan_amount",
            th: "Amount to disburse",
            render: loan =>
              `₦ ${this.$options.filters.currency(loan.loan_amount)}`
          },
          {
            name: "tenure",
            th: "Tenure",
            render: loan => `${loan?.tenure} Months`
          },
          {
            name: "interest_rate",
            th: "Interest Rate"
          },
          {
            name: "email",
            th: "Bank Account",
            render: loan => loan.user.profile?.account_no
          },
          {
            name: "phone",
            th: "Bank Name",
            render: loan =>
              this.$options.filters.bankName(
                loan.user.profile?.bank_name,
                this.banks
              )
          },
          {
            name: "salary_day",
            th: "Salary Day",
            render: loan => loan?.user?.profile?.salary_day || "N/A"
          },
          {
            name: "status",
            th: "Status",
            render: (loan, status) => {
              switch (status) {
                case "approved":
                  return `<div class="badge badge-green-soft-outline">
											Approved
										</div>`;
                case "accepted":
                  return `<div class="badge badge-green-soft-outline">
											Accepted
										</div>`;
                case "disbursed":
                  return `<div class="badge badge-green-soft-outline">
											Disbursed
										</div>`;
                case "declined":
                  return `<div class="badge badge-red-soft-outline">
											Declined
										</div>`;
                case "rejected":
                  return `<div class="badge badge-red-soft-outline">
											Rejected
										</div>`;
                case "paid":
                  return `<div class="badge badge-green-soft-outline">
											Paid
										</div>`;
                case "pending":
                default:
                  return `<div class="badge badge-orange-soft-outline">
											Pending
										</div>`;
              }
            }
          },
          {
            name: 'offer-letter',
            th: 'Offer Letter',
            render: (loan) => {
              if (loan.status.match(/approved|accepted|disbursed/)
                && loan?.source !== this.credpalPay)
              {
                return `
                  <a
                    href="/${this.ENV_VUE_APP_ADMIN_PATH}/offer-letter/${loan.id}"
                    target="_blank"
                    class="btn btn-sm btn-gray-soft"
                  >
                    View Offer Letter
                  </a>
                `
              }
            },
          },
        ],
        successes: [],
        failures: []
      }),
      form: this.$options.basicForm(["lender"]),
      getLoanData: this.$options.resource({}),
      loading: false,
      selectedLoan: null,
      verifyError: null,
      successMessage: null,
    };
  },
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    lenderOptions() {
      return this.lenders.data?.map(lender => ({
        title: lender.name,
        value: lender.id
      }));
    },
    selectedUser() {
      return this.loans.selected?.user;
    },
    totalRequestedAmount() {
      return this.getLoanData?.data?.totalRequested;
    },
    totalLoanAmount() {
      return this.getLoanData?.data?.totalLoanAmount;
    },
  },
  beforeMount() {
    // this.getLoans();
    this.getLenders();
    this.getTotalRequestedAndLoanAmount();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(loan) {
      this.loans.selected = loan;
      this.$refs.viewModal.open();
    },
    clear() {
      this.$refs.table.clearSelection();
    },
    selectionUpdate({ selection }) {
      this.selection = selection;
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    openOperationVerifyModal(loan) {
      this.selectedLoan = loan;
      this.$refs.operationVerifyModal.open();
    },
    async getLoans() {
      this.$refs.table.loadAjaxData();
    },
    async getLenders() {
      this.lenders.loading = true;
      await this.sendRequest("admin.lenders.all", {
        success: (response) => {
          this.lenders.data = response.data.lenders;
        }
      });
      this.lenders.loading = false;
    },
    async getTotalRequestedAndLoanAmount() {
      this.getLoanData.loading = true;
      await this.sendRequest("admin.loans.personal.total", "accepted", {
        success: (response) => (this.getLoanData.data = response.data.data),
        error: (error) => console.log(error)
      });
      this.getLoanData.loading = false;
    },
    async disburse() {
      if (!this.validateForm(this.form)) {
        return false;
      }

      this.loans.successes = [];
      this.loans.failures = [];

      this.form.loading = true;
      this.form.error = null;
      await this.sendRequest("admin.loans.personal.disburse_many", {
        data: {
          loans: this.selection.map(item => item.row.id),
          lender: this.form.data.lender.value
        },
        success: (response) => {
          const successes = response.data?.successes;
          const failures = response.data?.failures;

          this.loans.successes = successes;
          this.loans.failures = failures;

          this.$success({
            title: 'Loans Marked as Disbursed!',
            body: 'The user(s) will be notified that their bank account(s) have been credited.',
            button: 'Okay',
          });

          if (this.loans.successes.length) {
            this.clear();
            this.getLoans();
          }
        },
        error: (error) => {
          this.form.error = error;

          if (error?.response?.data?.failures) {
            this.loans.failures = error?.response?.data?.failures;
          }
        }
      });
      this.form.loading = false;
    },
    async confirmOperationVerify() {
      this.loading = true;
      await this.sendRequest(
        "admin.loans.personal.operationsVerify",
        this.selectedLoan?.id,
        {
          success: (response) => {
            console.log(45, response.data);
            this.reloadTable();
            this.$refs.operationVerifyModal.close();
            this.selectedLoan = null;
            this.successMessage = "Loan Verified By Operations Successfull";
            this.$refs.successModal.open();
          },
          error: () => {
            this.verifyError = "Loan Verification Failed";
          }
        }
      );
      this.loading = false;
    },
  }
};
</script>
